import {
  BankFilled,
  BankOutlined,
  ContainerOutlined,
  DeploymentUnitOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  ExperimentOutlined,
  FileSearchOutlined,
  FilterOutlined,
  FolderOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  MailOutlined,
  QuestionCircleOutlined,
  RadarChartOutlined,
  ReadOutlined,
  RetweetOutlined,
  SettingFilled,
  SisternodeOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserOutlined
} from "@ant-design/icons";
import classNames from "classnames";
import LocalesKeys from "@valencediscovery/kernel.locales";
import {
  HelpForm,
  HoverClickPopover,
  NavLink,
  UserAvatar,
  UserLabel,
  withAppState,
  WithFlag,
  withRouter
} from "@valencediscovery/kernel.react";
import { ServiceNames } from "@valencediscovery/kernel.services";
import {
  hasPermissions,
  PermissionActions,
  PermissionEntities,
  setSelectedOrganizationWithLocalStorage,
  setSideNavCollapsedState,
  SideNavCollapsedStates
} from "@valencediscovery/kernel.store";
import { OrganizationRoles } from "@valencediscovery/kernel.utils";
import { Avatar, Badge, Button, Menu, Modal, Tooltip } from "antd";
import { Auth } from "aws-amplify";
import _ from "lodash";
import { compose } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";
import { GiBrain } from "react-icons/gi";
import { MdDraw } from "react-icons/md";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { APP_NAME } from "../../../../config";
import Routes from "../../Routes";

const DEFAULT_OPENED_KEYS = ["organization-expanded", "user-section-expanded"];

class Navigation extends React.Component {
  helpForm = React.createRef();

  state = {
    visible: false,
    openKeys: DEFAULT_OPENED_KEYS.slice()
  };

  render() {
    const { location, isCollapsed, flags, flagOrgs, selectedOrganization } =
      this.props;
    const selectedOrganizationId = _.get(
      selectedOrganization,
      "organization_id"
    );

    const path = RegExp(
      _.last(location.pathname.split("/").filter(Boolean)) || Routes.home.key
    );
    const selectedKey =
      _.find(
        _.values(Routes),
        ({ key, main }) => !!main && RegExp(key).test(path)
      )?.key || "";

    const toggleCollapsed = () => {
      const nextCollapsedState = isCollapsed
        ? SideNavCollapsedStates.expanded
        : SideNavCollapsedStates.collapsed;
      this.props.setSideNavCollapsedState(nextCollapsedState);
      setTimeout(() => window.dispatchEvent(new Event("resize")), 4);
    };

    const handleMenuClick = (e) => {
      if (e.key === "toggle-sidebar") {
        toggleCollapsed();
      }
    };

    const menuItems = [
      { ...this.getOrganizationsSection({ isCollapsed }) },
      ...this.getCollapsedOrganizationSwitcher(),
      {
        type: "divider",
        className:
          "navigation-divider navigation-divider-top margin-top margin-bottom"
      },
      {
        key: Routes.homev2.key,
        className: "navigation-main-menu-item",
        label: (
          <NavLink
            route={Routes.homev2}
            icon={<HomeOutlined />}
            messageId={LocalesKeys.home}
          />
        )
      },
      {
        key: Routes.projects.key,
        className: "navigation-main-menu-item",
        hidden: !this.props.canViewProjects,
        label: (
          <NavLink
            route={Routes.projects}
            icon={<ReadOutlined />}
            messageId={LocalesKeys.projects}
          />
        )
      },
      {
        key: Routes.strategies.key,
        className: "navigation-main-menu-item",
        hidden: !this.props.canViewStrategies,
        label: (
          <NavLink
            route={Routes.strategies}
            icon={<ExperimentOutlined />}
            messageId={LocalesKeys.strategies}
          />
        )
      },
      {
        key: Routes.organizationMolecules.key,
        className: "navigation-main-menu-item",
        hidden: !this.props.canViewOrganizationMolecules,
        label: (
          <NavLink
            route={Routes.organizationMolecules}
            icon={<DeploymentUnitOutlined />}
            messageId={LocalesKeys.molecules}
          />
        )
      },
      {
        key: Routes.chemicalFilters.key,
        className: "navigation-main-menu-item",
        hidden: !this.props.canViewChemicalFilters,
        label: (
          <NavLink
            route={Routes.chemicalFilters}
            icon={<FilterOutlined />}
            messageId={LocalesKeys.chemicalFilters}
          />
        )
      },
      ...this.getMachineLearningSection({
        flagOrgs,
        flags,
        selectedOrganizationId,
        isCollapsed
      }),
      {
        key: Routes.molecularEditor.key,
        className: "navigation-main-menu-item",
        hidden: !this.props.canViewProjects,
        label: (
          <NavLink
            route={Routes.molecularEditor}
            icon={
              <span className="anticon anticon-filter">
                <MdDraw />
              </span>
            }
            messageId={LocalesKeys.molecularEditor}
          />
        )
      },
      {
        key: Routes.drive.key,
        className: "navigation-main-menu-item",
        hidden: !this.props.canViewDrive,
        label: (
          <NavLink
            route={Routes.drive}
            icon={<FolderOutlined />}
            messageId={LocalesKeys.drive}
          />
        )
      },
      { ...this.getTeamSection({ isCollapsed }) },
      {
        key: "UNAVIGABLE",
        className: "spacer"
      },
      {
        type: "divider",
        className: classNames("navigation-divider", {
          "navigation-divider-hidden": isCollapsed
        })
      },
      { ...this.getProfileSection({ isCollapsed }) },
      {
        type: "divider",
        className: classNames("navigation-divider", {
          "navigation-divider-hidden": isCollapsed
        })
      },
      ...this.getHelpOptions(isCollapsed),
      {
        type: "divider",
        className: "navigation-divider navigation-divider-bottom"
      },
      {
        key: "toggle-sidebar",
        title: null,
        className: "navigation-toggler",
        label: (
          <div
            id="toggle-sidebar"
            className="white text-center"
            onClick={toggleCollapsed}
          >
            {isCollapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
          </div>
        )
      }
    ].filter(({ hidden }) => !hidden);

    return (
      <>
        <Menu
          defaultSelectedKeys={[Routes.home.path]}
          selectedKeys={[selectedKey]}
          openKeys={this.state.openKeys}
          onOpenChange={(openKeys) => {
            this.setState({
              openKeys: _.uniq([...DEFAULT_OPENED_KEYS, ...openKeys])
            });
          }}
          mode="inline"
          theme="dark"
          className="navigation-menu"
          inlineCollapsed={isCollapsed}
          items={menuItems}
          onClick={handleMenuClick}
        />
        <HelpForm ref={this.helpForm} />
      </>
    );
  }

  getMachineLearningSection = ({
    flags,
    flagOrgs,
    selectedOrganizationId,
    isCollapsed
  }) => {
    const featureFlagWrapper = (obj, flagName) =>
      WithFlag.conditionalRender(obj, {
        flags,
        flagOrgs,
        selectedOrganizationId,
        flagName
      });

    const subSections = [
      {
        key: Routes.scoring.key,
        label: (
          <NavLink
            route={Routes.scoring}
            icon={<RadarChartOutlined />}
            messageId={LocalesKeys.scoring}
          />
        )
      },
      {
        key: Routes.allTasks.key,
        label: (
          <NavLink
            route={Routes.allTasks}
            icon={<UnorderedListOutlined />}
            messageId={LocalesKeys.tasks}
          />
        )
      },
      {
        key: "UNAVIGABLE",
        label: (
          <NavLink
            route={Routes.allTasks}
            icon={<SisternodeOutlined />}
            messageId={LocalesKeys.workflowDesign}
          />
        )
      },
      {
        key: Routes.genericTemplates.key,
        label: (
          <NavLink
            route={Routes.genericTemplates}
            icon={<ContainerOutlined />}
            messageId={LocalesKeys.templates}
          />
        )
      }
    ];

    let finalMLSection;
    if (isCollapsed) {
      finalMLSection = featureFlagWrapper(
        {
          key: "computational-tasks",
          icon: (
            <span className="anticon">
              <GiBrain />
            </span>
          ),
          className: "navigation-main-menu-item",
          hidden: !this.props.canViewMachineLearning,
          label: <FormattedMessage id={LocalesKeys.computationalTasks} />,
          children: [
            featureFlagWrapper(
              {
                label: <FormattedMessage id={LocalesKeys.computationalTasks} />,
                key: "computational-tasks-collapsed-label",
                type: "group",
                children: subSections
              },
              "computational-tasks"
            )
          ]
        },
        "computational-tasks"
      );
    } else {
      finalMLSection = featureFlagWrapper(
        {
          key: "computational-tasks",
          icon: (
            <span className="anticon">
              <GiBrain />
            </span>
          ),
          className: "navigation-main-menu-item",
          hidden: !this.props.canViewMachineLearning,
          label: <FormattedMessage id={LocalesKeys.computationalTasks} />,
          children: subSections
        },
        "computational-tasks"
      );
    }

    return _.some(finalMLSection, _.identity) ? [finalMLSection] : [];
  };

  getTeamSection = ({ isCollapsed }) => {
    const subSections = [
      {
        key: Routes.members.key,
        label: (
          <NavLink
            route={Routes.members}
            icon={<UserOutlined />}
            messageId={LocalesKeys.members}
          />
        )
      },
      {
        key: Routes.groups.key,
        label: (
          <NavLink
            route={Routes.groups}
            icon={<TeamOutlined />}
            messageId={LocalesKeys.groups}
          />
        )
      }
    ];

    if (isCollapsed) {
      return {
        key: "team-iam-management",
        className: "navigation-main-menu-item",
        icon: <TeamOutlined />,
        hidden:
          (!this.props.canViewMembers && !this.props.canViewGroups) ||
          this.props.isDataManager,
        label: <FormattedMessage id={LocalesKeys.organization} />,
        children: [
          {
            label: <FormattedMessage id={LocalesKeys.organization} />,
            children: subSections,
            key: "team-iam-management-collapsed-label",
            type: "group"
          }
        ]
      };
    }

    return {
      key: "team-iam-management",
      className: "navigation-main-menu-item",
      icon: <TeamOutlined />,
      hidden: !this.props.canViewMembers && !this.props.canViewGroups,
      label: <FormattedMessage id={LocalesKeys.organization} />,
      children: subSections
    };
  };

  getProfileSection = ({ isCollapsed }) => {
    const logout = () => {
      Auth.signOut().then(() => {
        window.location.reload();
      });
    };

    const subSections = [
      {
        key: Routes.profileSettings.key,
        label: (
          <NavLink
            route={Routes.profileSettings}
            icon={<UserOutlined />}
            messageId={LocalesKeys.profileSettings}
          />
        )
      },
      {
        key: "UNAVIGABLE",
        icon: <LogoutOutlined />,
        label: (
          <span onClick={logout}>
            <FormattedMessage id={LocalesKeys.signOut} />
          </span>
        )
      }
    ];
    if (isCollapsed) {
      return {
        key: "user-section-collapsed",
        className: "navigation-avatar-icon-collapsed",
        icon: (
          <span
            className="anticon"
            title={UserLabel({ user: this.props.user })}
          >
            <UserAvatar user={this.props.user} size={28} />
          </span>
        ),
        label: UserLabel({ user: this.props.user }),
        children: [
          {
            label: UserLabel({ user: this.props.user }),
            children: subSections,
            key: "profile-collapsed-label",
            type: "group"
          }
        ]
      };
    } else {
      return {
        key: "user-section-expanded",
        className: "navigation-user-avatar-icon-expanded navigation-user-menu",
        icon: (
          <span
            className="anticon"
            title={UserLabel({ user: this.props.user })}
          >
            <UserAvatar user={this.props.user} size={28} />
          </span>
        ),
        label: UserLabel({ user: this.props.user }),
        children: subSections
      };
    }
  };

  getOrganizationsSection = ({ isCollapsed }) => {
    const subSections = [];

    if (this.props.canViewLicense) {
      subSections.push({
        key: Routes.organizationSettings.key,
        label: (
          <NavLink
            route={Routes.organizationSettings}
            icon={
              <SettingFilled
                style={!isCollapsed ? { fontSize: 10 } : undefined}
              />
            }
            messageId={LocalesKeys.settings}
          />
        )
      });
    }

    const organizationIcon = (
      <span>
        <div>
          <Badge
            offset={[-5, 4]}
            count={<BankFilled style={{ fontSize: "12px", color: "#fff" }} />}
          >
            <Avatar
              size={28}
              shape="square"
              style={{ backgroundColor: "#1677ff" }}
            >
              {this.props.selectedOrganization.organization_name[0]}
            </Avatar>
          </Badge>
        </div>
      </span>
    );
    if (isCollapsed) {
      return {
        key: "organization-collapsed",
        className: "navigation-avatar-icon-collapsed",
        icon: organizationIcon,
        label: this.props.selectedOrganization.organization_name,
        children: [
          {
            label: this.props.selectedOrganization.organization_name,
            children: subSections,
            className: "navigation-top-org-menu-collapsed",
            key: "organizations-collapsed-label",
            type: "group"
          }
        ]
      };
    } else {
      const icon =
        this.props.organizationMemberships.length === 1 ? (
          <BankOutlined style={{ fontSize: 12 }} />
        ) : (
          <HoverClickPopover
            placement="bottomLeft"
            hoverContent={
              <span>
                {this.props.intl.formatMessage({
                  id: LocalesKeys.clickToSwitchOrg
                })}
              </span>
            }
            clickContent={this.getOrganizationButtons()}
          >
            <RetweetOutlined className="link-blue" style={{ fontSize: 14 }} />
          </HoverClickPopover>
        );
      return {
        key: "organization-expanded",
        className:
          "navigation-top-org-menu navigation-org-avatar-icon-expanded navigation-org-name",
        icon: icon,
        label: (
          <div
            className="no-margin full-width overflow-ellipsis"
            title={this.props.selectedOrganization.organization_name}
          >
            {this.props.selectedOrganization.organization_name}
          </div>
        ),
        children: subSections
      };
    }
  };

  getCollapsedOrganizationSwitcher() {
    return this.props.organizationMemberships.length > 1 &&
      !!this.props.isCollapsed
      ? [
          {
            key: "organization",
            className: "navigation-org-switcher",
            icon: (
              <HoverClickPopover
                placementHovered="right"
                placementClicked="rightTop"
                hoverContent={
                  <span>
                    {this.props.intl.formatMessage({
                      id: LocalesKeys.clickToSwitchOrg
                    })}
                  </span>
                }
                clickContent={this.getOrganizationButtons()}
              >
                <RetweetOutlined
                  className="link-blue"
                  style={{ fontSize: 14 }}
                />
              </HoverClickPopover>
            )
          }
        ]
      : [];
  }

  getSelectedOrganizationId = () => {
    return this.props.selectedOrganization
      ? this.props.selectedOrganization.organization_id
      : "NO ORG CREATED";
  };

  getOrganizationOptions = () => {
    return _.sortBy(
      this.props.organizationMemberships,
      ({ organization_name }) => organization_name.toLowerCase()
    ).filter(
      ({ organization_services }) =>
        _.includes(organization_services, this.props.appName) ||
        this.props.appName === ServiceNames.api
    );
  };

  getOrganizationButtons() {
    const selectedOrganizationId = this.getSelectedOrganizationId();
    return _.chain(this.getOrganizationOptions())
      .map((organizationMembership) => {
        const { organization_name, organization_id } = organizationMembership;
        return (
          <Button
            key={organization_id}
            type="link"
            onClick={() => {
              this.promptRedirectToNewOrg(organizationMembership);
            }}
            disabled={
              organizationMembership.organization_id === selectedOrganizationId
            }
            className="display-block"
          >
            <div title={organization_name} className="organization-text">
              {organization_name}
            </div>
          </Button>
        );
      })
      .value();
  }

  getOrganizationMenuOptions = () => {
    const selectedOrganizationId = this.getSelectedOrganizationId();
    const orgChildren = this.getOrganizationOptions().map(
      (organizationMembership) => {
        const { organization_name, organization_id } = organizationMembership;
        return {
          key: organization_id,
          label: (
            <span
              onClick={() => {
                this.promptRedirectToNewOrg(organizationMembership);
              }}
            >
              {organization_name}
            </span>
          ),
          disabled: organization_id === selectedOrganizationId
        };
      }
    );
    return orgChildren;
  };

  getHelpOptions(isCollapsed) {
    let betaNotice = (
      <div>
        <InfoCircleOutlined />
        <span>
          <FormattedMessage id={LocalesKeys.betaNoticeShort} />
        </span>
      </div>
    );
    betaNotice = isCollapsed ? (
      betaNotice
    ) : (
      <div>
        <Tooltip
          title={
            <div>
              {this.props.intl.formatMessage(
                {
                  id: LocalesKeys.betaNotice
                },
                { p: (...chunks) => <p>{chunks}</p>, appName: APP_NAME }
              )}
            </div>
          }
          placement="left"
          arrowPointAtCenter
        >
          {betaNotice}
        </Tooltip>
      </div>
    );

    const children = [
      {
        key: "documentation-link",
        className: classNames({
          "help-option-item-expanded help-option-item-expanded-first":
            !isCollapsed
        }),
        label: (
          <div
            id="documentation"
            onClick={() =>
              window.open(
                "https://valencediscovery.notion.site/Kernel-Documentation-ee03ae246ab946ee9c0993f10ab5a3e3",
                "_blank"
              )
            }
          >
            <FileSearchOutlined />
            <span className="margin-left-half">
              <FormattedMessage id={LocalesKeys.documentation} />
            </span>
          </div>
        )
      },
      {
        key: "contact-us",
        className: classNames({ "help-option-item-expanded": !isCollapsed }),
        label: (
          <div id="help-form-icon" onClick={() => this.helpForm.current.show()}>
            <MailOutlined />
            <span className="margin-left-half">
              <FormattedMessage id={LocalesKeys.contactUs} />
            </span>
          </div>
        )
      },
      {
        key: "UNAVIGABLE",
        style: { color: "#ff9900" },
        className: classNames({
          "help-option-item-expanded help-option-item-expanded-last":
            !isCollapsed
        }),
        title: (
          <div>
            {this.props.intl.formatMessage(
              {
                id: LocalesKeys.betaNotice
              },
              { p: (...chunks) => <p>{chunks}</p>, appName: APP_NAME }
            )}
          </div>
        ),
        label: betaNotice
      }
    ];

    return isCollapsed
      ? [
          {
            key: "help-options",
            className: "navigation-help-menu",
            icon: <QuestionCircleOutlined />,
            label: <FormattedMessage id={LocalesKeys.help} />,
            children: children
          }
        ]
      : children;
  }

  promptRedirectToNewOrg(organizationMembership) {
    Modal.confirm({
      title: this.props.intl.formatMessage({ id: LocalesKeys.areYouSure }),
      content: (
        <span>
          {this.props.intl.formatMessage(
            {
              id: LocalesKeys.redirectToHomePageOfNextOrg
            },
            {
              p: (...chunks) => <p>{chunks}</p>,
              strong: (...chunks) => <strong>{chunks}</strong>,
              organization: organizationMembership.organization_name
            }
          )}
        </span>
      ),
      okText: this.props.intl.formatMessage({ id: LocalesKeys.continue }),
      cancelText: this.props.intl.formatMessage({ id: LocalesKeys.cancel }),
      onOk: () => {
        this.redirectToNewOrg(organizationMembership);
      }
    });
  }

  redirectToNewOrg(organizationMembership) {
    this.props.setSelectedOrganization(organizationMembership);
    this.props.history.push(this.props.homePath);
  }

  renderNavItem = (navItem) => {
    return navItem.hidden ? [] : [navItem];
  };
}

Navigation.propTypes = {
  isCollapsed: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  canViewProjects: hasPermissions(state, [
    [PermissionActions.view, PermissionEntities.project]
  ]),
  canViewStrategies: hasPermissions(state, [
    [PermissionActions.view, PermissionEntities.strategy]
  ]),
  canViewChemicalFilters: hasPermissions(state, [
    [PermissionActions.view, PermissionEntities.chemicalfilter]
  ]),
  canViewMachineLearning: hasPermissions(state, [
    [PermissionActions.view, PermissionEntities.basetask],
    [PermissionActions.view, PermissionEntities.generictemplate]
  ]),
  canViewMoleculeEditor: hasPermissions(state, [
    [PermissionActions.view, PermissionEntities.project]
  ]),
  canViewDrive: hasPermissions(state, [
    [PermissionActions.view, PermissionEntities.filenode],
    [PermissionActions.view, PermissionEntities.treenode],
    [PermissionActions.view, PermissionEntities.foldernode]
  ]),
  canViewMembers: hasPermissions(state, [
    [PermissionActions.view, PermissionEntities.member]
  ]),
  canViewGroups: hasPermissions(state, [
    [PermissionActions.view, PermissionEntities.group]
  ]),
  canViewLicense: hasPermissions(state, [
    [PermissionActions.view, PermissionEntities.organizationlicense]
  ]),
  canViewOrganizationMolecules: hasPermissions(state, [
    [PermissionActions.view, PermissionEntities.organizationmolecule]
  ]),
  isDataManager:
    !state.selectedOrganization ||
    state.selectedOrganization?.role === OrganizationRoles.dataManager
});

const mapDispatchToProps = (dispatch) => ({
  setSideNavCollapsedState: (collapsedState = "collapsed") =>
    dispatch(setSideNavCollapsedState(collapsedState)),
  setSelectedOrganization: (selectedOrganization) =>
    dispatch(setSelectedOrganizationWithLocalStorage(selectedOrganization))
});

export default compose(
  (component) => injectIntl(component, { forwardRef: true }),
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true }),
  withAppState,
  withRouter
)(Navigation);
