import {
  BarChartOutlined,
  BlockOutlined,
  CalculatorOutlined,
  DotChartOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  RadarChartOutlined,
  ShareAltOutlined,
  TableOutlined,
  UnorderedListOutlined
} from "@ant-design/icons";
import LocalesKeys from "@valencediscovery/kernel.locales";
import {
  NavLink,
  withAppState,
  withRouter
} from "@valencediscovery/kernel.react";
import {
  hasPermissions,
  PermissionActions,
  PermissionEntities
} from "@valencediscovery/kernel.store";
import { Menu } from "antd";
import _ from "lodash";
import { compose } from "lodash/fp";
import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import Routes from "../../Routes";

class MLSideNavigation extends React.Component {
  static COLLAPSED_STORAGE_KEY = "invivoai_ml_side_nav_state";

  constructor(props) {
    super(props);

    const { COLLAPSED_STORAGE_KEY } = MLSideNavigation;
    this.state = {
      collapsed:
        window.localStorage.getItem(COLLAPSED_STORAGE_KEY) === "collapsed"
    };
  }

  render() {
    const { location } = this.props;

    const path = RegExp(
      _.last(location.pathname.split("/").filter(Boolean)) || Routes.home.key
    );
    const selectedKey =
      _.find(
        _.values(Routes),
        ({ key, main }) => !!main && RegExp(key).test(path)
      )?.key || "";

    const menuItems = [
      {
        key: Routes.allTasks.key,
        disabled: !this.props.hasViewScoring,
        label: (
          <NavLink
            route={Routes.allTasks}
            icon={<UnorderedListOutlined />}
            messageId={LocalesKeys.allTasks}
          />
        )
      },
      {
        key: Routes.scoring.key,
        disabled: !this.props.hasViewScoring,
        label: (
          <NavLink
            route={Routes.scoring}
            icon={<RadarChartOutlined />}
            messageId={LocalesKeys.scoring}
          />
        )
      },
      {
        key: Routes.distributedScoring.key,
        disabled: !this.props.hasViewDistributedScoring,
        label: (
          <NavLink
            route={Routes.distributedScoring}
            icon={<DotChartOutlined />}
            messageId={LocalesKeys.distributedScoringSideNavTitle}
          />
        )
      },

      {
        key: Routes.descriptors.key,
        disabled: !this.props.hasViewDescriptors,
        label: (
          <NavLink
            route={Routes.descriptors}
            icon={<CalculatorOutlined />}
            messageId={LocalesKeys.descriptorsSideNavTitle}
          />
        )
      },
      {
        key: Routes.featurize.key,
        disabled: !this.props.hasViewFeaturize,
        label: (
          <NavLink
            route={Routes.featurize}
            icon={<BlockOutlined />}
            messageId={LocalesKeys.featurize}
          />
        )
      },
      {
        key: Routes.modelSelection.key,
        disabled: !this.props.hasViewModelSelection,
        label: (
          <NavLink
            route={Routes.modelSelection}
            icon={<ShareAltOutlined rotate={180} />}
            messageId={LocalesKeys.modelSelectionTasksSideNavTitle}
          />
        )
      },
      {
        key: Routes.modelAnalysis.key,
        disabled: true,
        label: (
          <NavLink
            route={Routes.modelAnalysis}
            icon={<BarChartOutlined />}
            messageId={LocalesKeys.modelAnalysisTasksSideNavTitle}
          />
        )
      },
      {
        key: Routes.dataProcessing.key,
        disabled: true,
        label: (
          <NavLink
            route={Routes.dataProcessing}
            icon={<TableOutlined />}
            messageId={LocalesKeys.dataProcessingTasksSideNavTitle}
          />
        )
      },
      {
        key: "7",
        className: "spacer"
      },
      {
        key: "toggle-sidebar",
        title: null,
        className: "side-nav-toggler",
        label: (
          <div
            id="toggle-sidebar"
            className="font-xsmall text-center"
            onClick={() => {
              const collapsedState = !this.state.collapsed;
              const storageCollapsedState = !!collapsedState
                ? "collapsed"
                : "expanded";
              this.setState({ collapsed: collapsedState });
              window.localStorage.setItem(
                MLSideNavigation.COLLAPSED_STORAGE_KEY,
                storageCollapsedState
              );
            }}
          >
            {this.state.collapsed ? (
              <DoubleRightOutlined />
            ) : (
              <DoubleLeftOutlined />
            )}
          </div>
        )
      }
    ];

    return (
      <Menu
        defaultSelectedKeys={[Routes.home.path]}
        selectedKeys={[selectedKey]}
        mode="inline"
        theme="light"
        className="subnavigation-menu"
        inlineCollapsed={this.state.collapsed}
        items={menuItems}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  hasViewFeaturize: hasPermissions(state, [
    [PermissionActions.view, PermissionEntities.featurizetask]
  ]),
  hasViewDescriptors: hasPermissions(state, [
    [PermissionActions.view, PermissionEntities.descriptorstask]
  ]),
  hasViewScoring: hasPermissions(state, [
    [PermissionActions.view, PermissionEntities.scoringtask]
  ]),
  hasViewDistributedScoring: hasPermissions(state, [
    [PermissionActions.view, PermissionEntities.distributedscoringtask]
  ]),
  hasViewModelSelection: hasPermissions(state, [
    [PermissionActions.view, PermissionEntities.modelselectiontask]
  ]),
  hasViewModelAnalysis: hasPermissions(state, [
    [PermissionActions.view, PermissionEntities.modelanalysistask]
  ]),
  hasViewDataProcess: hasPermissions(state, [
    [PermissionActions.view, PermissionEntities.dataprocesstask]
  ])
});

export default compose(
  (component) => injectIntl(component, { forwardRef: true }),
  withAppState,
  connect(mapStateToProps, null, null, { forwardRef: true }),
  withRouter
)(MLSideNavigation);
