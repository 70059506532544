import LocalesKeys from "@valencediscovery/kernel.locales";
import {
  PageHeader,
  ScoringWidget,
  ScoringTasksTable,
  withAppState,
  withDocumentTitle,
  withPermissionRestriction,
  withMainWrapperAndSideNavigation
} from "@valencediscovery/kernel.react";
import { KernelAPI } from "@valencediscovery/kernel.services";
import {
  PermissionActions,
  PermissionEntities
} from "@valencediscovery/kernel.store";
import { retrieveIntlMessage } from "@valencediscovery/kernel.utils";
import { compose } from "lodash/fp";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { titleCase } from "title-case";
import MLSideNavigation from "../apps/kernel/components/MLSideNavigation/MLSideNavigation";
import { APP_NAME } from "../config";
import { AppContext } from "../context";

class ScoringTasks extends React.Component {
  static contextType = AppContext;
  static displayName = "ScoringTasks";

  kernel = KernelAPI();

  render() {
    return this.getPageContent();
  }

  getPageContent() {
    return (
      <>
        <PageHeader
          title={
            <FormattedMessage
              id={LocalesKeys.scoringTaskHistoryTitle}
              children={([title]) => titleCase(title)}
            />
          }
          description={
            <FormattedMessage id={LocalesKeys.scoringTaskHistoryDescription} />
          }
          actions={this.getPageActions()}
          breadcrumbs={[
            {
              title: this.props.intl.formatMessage({ id: LocalesKeys.home }),
              path: this.context.routes.home.path
            },
            {
              title: this.props.intl.formatMessage({
                id: LocalesKeys.computationalTasks
              })
            },
            {
              title: this.props.intl.formatMessage({
                id: LocalesKeys.scoringTaskHistoryTitle
              })
            }
          ]}
        />
        <ScoringTasksTable />
      </>
    );
  }

  getPageActions() {
    return <ScoringWidget projectSelection={true} />;
  }
}

export default compose(
  injectIntl,
  withAppState,
  withDocumentTitle({
    name: retrieveIntlMessage(LocalesKeys.scoringPageTitle).format(),
    prefix: APP_NAME
  }),
  withPermissionRestriction({
    permissions: [[PermissionActions.view, PermissionEntities.scoringtask]]
  }),
  withMainWrapperAndSideNavigation({ SideNavigation: MLSideNavigation })
)(ScoringTasks);
