import LocalesKeys from "@valencediscovery/kernel.locales";
import { Mercure } from "@valencediscovery/kernel.mercure";
import {
  AppLoad,
  UserProfilePrompt,
  withAppState,
  withRouter
} from "@valencediscovery/kernel.react";
import {
  ENV,
  Environment,
  getAPIRoot
} from "@valencediscovery/kernel.services";
import {
  getMyself,
  hasPermissions,
  PermissionActions,
  PermissionEntities,
  setRDKitState,
  setSideNavCollapsedState,
  SideNavCollapsedStates
} from "@valencediscovery/kernel.store";
import {
  savePostLoginRedirectUrlInLocalStorage,
  setupHotjar
} from "@valencediscovery/kernel.utils";
import { message, Modal } from "antd";
import { Auth } from "aws-amplify";
import Axios from "axios";
import _ from "lodash";
import { compose } from "lodash/fp";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { APP_NAME } from "../../../../config";
import Routes from "../../Routes";
import Navigation from "../Navigation/Navigation";
import "./AppLayout.scss";
import "../Navigation/Navigation.scss";

class AppLayout extends Component {
  static getMyself = _.once((callback) => callback());

  toggleCollapsed = () => {
    const nextCollapsedState =
      this.props.sideNavCollapsedState === SideNavCollapsedStates.collapsed
        ? SideNavCollapsedStates.expanded
        : SideNavCollapsedStates.collapsed;

    this.props.setSideNavCollapsedState(nextCollapsedState);
    setTimeout(() => window.dispatchEvent(new Event("resize")), 4);
  };

  componentDidMount() {
    this.setupRDKit();

    this.setOrganizationIdQueryParam();

    if (ENV === Environment.prod) {
      setupHotjar();
    }
  }

  setupRDKit() {
    window
      .initRDKitModule()
      .then((instance) => {
        window.RDKit = instance;
        this.props.setRDKitState("LOADED");
      })
      .catch(() => {
        this.props.setRDKitState("ERROR");
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.authState === "signedIn") {
      AppLayout.getMyself(this.props.getMyself.bind(this));
    }

    if (this.props.location !== prevProps.location) {
      Modal.destroyAll();
    }
  }

  render() {
    const applicationLoadedSuccessfully = this.applicationLoadedSuccessfully();

    if (!_.includes(["loading", "signedIn"], this.props.authState)) {
      return this.redirectToSignIn();
    } else if (
      this.props.authState === "loading" ||
      !applicationLoadedSuccessfully
    ) {
      return <AppLoad appName={APP_NAME} />;
    } else if (
      applicationLoadedSuccessfully &&
      this.checkIfUserHasAccessToApp()
    ) {
      return this.getMainApplication();
    }

    Auth.signOut().then(() => {
      message
        .error(
          this.props.intl.formatMessage({
            id: LocalesKeys.applicationLoadFailed
          })
        )
        .then(() => window.location.reload(true));
    });

    return "Logging out...";
  }

  redirectToSignIn() {
    savePostLoginRedirectUrlInLocalStorage();
    window.history.replaceState({}, "", "/");
    return null;
  }

  getMainApplication() {
    const hasIncompleteProfile = !this.props.user.userprofile.full_name;

    const incompleteProfilePrompt = (
      <div className="authentication-container">
        <div className="authentication-form">
          <h2 className="margin-bottom-2">
            {this.props.intl.formatMessage({
              id: LocalesKeys.completeProfileInformation
            })}
          </h2>
          <UserProfilePrompt
            buttonSize="large"
            extraButtonClasses="authentication-button margin-top-half"
          />
        </div>
      </div>
    );

    const mainApp = (
      <>
        <div className="app-container">
          <Navigation
            homePath={Routes.homev2.path}
            appName={process.env.REACT_APP_APP_NAME}
            isCollapsed={
              this.props.sideNavCollapsedState ===
              SideNavCollapsedStates.collapsed
            }
          />
          <div
            className="main-wrapper-hide"
            onClick={() => document.querySelector(".navigation-toggle").click()}
          ></div>
          {this.props.children}
        </div>
      </>
    );

    return hasIncompleteProfile ? incompleteProfilePrompt : mainApp;
  }

  applicationLoadedSuccessfully() {
    const applicationLoadedSuccessfully =
      this.props.authState === "signedIn" &&
      !!this.props.user &&
      (!!this.props.selectedOrganization || this.props.user.is_superuser) &&
      this.props.applicationLoaded;

    return applicationLoadedSuccessfully;
  }

  checkIfUserHasAccessToApp() {
    /**
     * User must belong to at least one organization subscribed to the app/service
     * he is trying to signed in to.
     */
    return (
      !!this.props.organizationMemberships &&
      _.some(this.props.organizationMemberships, (membership) =>
        _.includes(
          membership.organization_services,
          process.env.REACT_APP_APP_NAME
        )
      )
    );
  }

  setOrganizationIdQueryParam = () => {
    Axios.interceptors.request.use((config) => {
      const isRequestToInVivoRestAPI = _.includes(config.url, getAPIRoot());
      if (isRequestToInVivoRestAPI) {
        const selectedOrganizationId = _.get(
          this.props,
          "selectedOrganization.organization_id",
          undefined
        );
        config.params = config.params || {};
        config.params["organization"] = selectedOrganizationId;
        config.headers["Origin-Href"] = window.location.href;
      }

      return Promise.resolve(config);
    });
  };
}

AppLayout.propTypes = {
  authState: PropTypes.string.isRequired,
  rootPath: PropTypes.string.isRequired,
  homePath: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  canViewLicense: hasPermissions(state, [
    [PermissionActions.view, PermissionEntities.organizationlicense]
  ])
});

const onAppLoad = (dispatch) => {
  Mercure.subscribe(
    Mercure.generateOrgTopics(..._.values(Mercure.Topics)),
    () => {
      dispatch(getMyself(undefined, process.env.REACT_APP_APP_NAME, false));
    },
    10000
  );
};
const mapDispatchToProps = (dispatch) => ({
  getMyself: () =>
    dispatch(getMyself(onAppLoad, process.env.REACT_APP_APP_NAME)),
  setRDKitState: (state) => dispatch(setRDKitState(state)),
  setSideNavCollapsedState: (collapsedState = "collapsed") =>
    dispatch(setSideNavCollapsedState(collapsedState))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withAppState,
  withRouter
)(AppLayout);
