const UNNAVIGABLE = "UNNAVIGABLE";

const Routes = {
  root: {
    path: "/",
    key: ""
  },
  profileSettings: {
    path: "/profile-settings",
    main: true,
    key: "profile"
  },
  molecularEditor: {
    path: "/molecular-editor",
    key: "molecular-editor",
    main: true
  },
  home: {
    path: "/home",
    key: "home"
  },
  homev2: {
    path: "/home-v2",
    key: "home",
    main: true
  },
  drive: {
    path: "/drive",
    key: "drive",
    main: true
  },
  driveTrash: {
    path: "/drive/trash",
    key: "drive",
    main: true
  },
  shareDriveFile: {
    path: "/drive/share/:file",
    key: "drive"
  },
  members: {
    path: "/members",
    key: "member",
    main: true
  },
  addMember: {
    path: "/add-member",
    key: "member"
  },
  editMember: {
    path: "/edit-member/:id",
    key: "member"
  },
  groups: {
    path: "/groups",
    key: "group",
    main: true
  },
  addGroup: {
    path: "/add-group",
    key: "group"
  },
  editGroup: {
    path: "/edit-group/:id",
    key: "group"
  },
  organizationSettings: {
    path: "/team-settings",
    key: "team-setting",
    main: true,
    superUser: true
  },
  moleculeEditorHelp: {
    path: "/molecule-editor-help",
    key: UNNAVIGABLE
  },
  organizationMolecules: {
    path: "/team-molecules",
    key: "molecules",
    main: true
  },
  projects: {
    path: "/projects",
    key: "project",
    main: true
  },
  addProject: {
    path: "/add-project",
    key: "project"
  },
  editProject: {
    path: "/edit-project/:id",
    key: "project"
  },
  viewProject: {
    path: "/view-project/:id",
    key: "project"
  },
  strategies: {
    path: "/strategies",
    key: "strat",
    main: true
  },
  addStrategy: {
    path: "/add-strategy",
    key: "strat"
  },
  viewStrategy: {
    path: "/project/:projectId/strategy/:id",
    key: "strat"
  },
  strategy: {
    path: "/project/:projectId/strategy/:id/review/:reviewId",
    key: "strat"
  },
  strategySummary: {
    path: "/project/:projectId/strategy/:id/review-summary",
    key: "strat"
  },
  chemicalFilters: {
    path: "/chemical-filters",
    key: "chemical-filter",
    main: true
  },
  addChemicalFilter: {
    path: "/add-chemical-filter",
    key: "chemical-filter"
  },
  editChemicalFilter: {
    path: "/edit-chemical-filter/:id",
    key: "chemical-filter"
  },
  scoring: {
    path: "/machine-learning/scoring-tasks",
    key: "scoring",
    main: true
  },
  distributedScoring: {
    path: "/machine-learning/distributed-score-tasks",
    key: "distributed-score",
    main: true
  },
  addDistributedScoringTask: {
    path: "/machine-learning/add-distributed-score-tasks",
    key: "distributed-score"
  },
  featurize: {
    path: "/machine-learning/featurize-tasks",
    key: "featurize",
    main: true
  },
  addFeaturizeTask: {
    path: "/machine-learning/add-featurize-task",
    key: "featurize"
  },
  descriptors: {
    path: "/machine-learning/descriptors-tasks",
    key: "descriptors",
    main: true
  },
  addDescriptorsTask: {
    path: "/machine-learning/add-descriptors-task",
    key: "descriptors"
  },
  modelSelection: {
    path: "/machine-learning/model-selection-tasks",
    key: "model-selection",
    main: true
  },
  addModelSelectionTask: {
    path: "/machine-learning/add-model-selection-task",
    key: "model-selection"
  },
  genericTemplates: {
    path: "/machine-learning/generic-templates",
    key: "template",
    main: true
  },
  addGenericTemplate: {
    path: "/machine-learning/add-generic-template",
    key: "template"
  },
  editGenericTemplate: {
    path: "/machine-learning/edit-generic-template/:id",
    key: "template"
  },
  allTasks: {
    path: "/machine-learning/all-tasks",
    key: "all-tasks",
    main: true
  },
  dataProcessing: {
    path: "/machine-learning/data-processing",
    key: "data-processing",
    main: true
  },
  modelAnalysis: {
    path: "/machine-learning/model-analysis",
    key: "model-analysis",
    main: true
  },
  workspaceView: {
    path: "/project/:projectId/strategy/:id/workspace",
    key: "workspace",
    main: true
  }
};

export default Routes;
