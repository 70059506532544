import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as Sentry from "@sentry/react";
import { AppLoad, withFlag } from "@valencediscovery/kernel.react";
import {
  ENV,
  Environment,
  getAPIRoot,
  getGAMetaData,
  setupGoogleAnalytics
} from "@valencediscovery/kernel.services";
import { Store } from "@valencediscovery/kernel.store";
import { Auth } from "aws-amplify";
import Axios from "axios";
import { StatusCodes } from "http-status-codes";
import _ from "lodash";
import React, { lazy, Suspense } from "react";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from "react-router-dom";
import { APP_NAME } from "../../config";
import { AppContext } from "../../context";
import AppLayout from "./components/AppLayout/AppLayout";
import { default as AllTasksWithoutFlag } from "../../pages/AllTasks";
import { default as ScoringTasksWithoutFlag } from "../../pages/ScoringTasks";
import { default as DistributedScoringTasksWithoutFlag } from "../../pages/DistributedScoringTasks";
import { default as DescriptorsTasksWithoutFlag } from "../../pages/DescriptorsTasks";
import { default as FeaturizeTasksWithoutFlag } from "../../pages/FeaturizeTasks";
import { default as ModelSelectionTasksWithoutFlag } from "../../pages/ModelSelectionTasks";

const OrganizationSettings = lazy(() =>
  import("../../pages/OrganizationSettings")
);
const Members = lazy(() => import("../../pages/Members"));
const AddMember = lazy(() => import("../../pages/AddMember"));
const EditMember = lazy(() => import("../../pages/EditMember"));
const Groups = lazy(() => import("../../pages/Groups"));
const AddGroup = lazy(() => import("../../pages/AddGroup"));
const EditGroup = lazy(() => import("../../pages/EditGroup"));
const HomeV2 = lazy(() => import("../../pages/HomeV2"));
const Drive = lazy(() => import("../../pages/Drive"));
const DriveTrash = lazy(() => import("../../pages/DriveTrash"));
const ShareDriveFile = lazy(() => import("../../pages/ShareDriveFile"));
const AddProject = lazy(() => import("../../pages/AddProject"));
const StrategyReview = lazy(() => import("../../pages/StrategyReview"));
const MoleculeEditorHelp = lazy(() => import("../../pages/MoleculeEditorHelp"));
const ProfileSettings = lazy(() => import("../../pages/ProfileSettings"));
const Projects = lazy(() => import("../../pages/Projects"));
const ViewProject = lazy(() => import("../../pages/ViewProject"));
const Strategies = lazy(() => import("../../pages/Strategies"));
const ViewStrategy = lazy(() => import("../../pages/ViewStrategy"));
const AddStrategy = lazy(() => import("../../pages/AddStrategy"));
const ChemicalFilters = lazy(() => import("../../pages/ChemicalFilters"));
const AddChemicalFilter = lazy(() => import("../../pages/AddChemicalFilter"));
const EditChemicalFilter = lazy(() => import("../../pages/EditChemicalFilter"));
const MolecularEditor = lazy(() => import("../../pages/MolecularEditor"));
const OrganizationMolecules = lazy(() =>
  import("../../pages/OrganizationMolecules")
);

const computationalTasksFlagWrapper = withFlag({
  flagName: "computational-tasks",
  showUnauthorized: true
});

const workspaceFlagWrapper = withFlag({
  flagName: "workspace",
  showUnauthorized: true
});

const AllTasks = computationalTasksFlagWrapper(AllTasksWithoutFlag);
const ScoringTasks = computationalTasksFlagWrapper(ScoringTasksWithoutFlag);
const DistributedScoringTasks = computationalTasksFlagWrapper(
  DistributedScoringTasksWithoutFlag
);
const FeaturizeTasks = computationalTasksFlagWrapper(FeaturizeTasksWithoutFlag);
const ModelSelectionTasks = computationalTasksFlagWrapper(
  ModelSelectionTasksWithoutFlag
);
const DescriptorsTasks = computationalTasksFlagWrapper(
  DescriptorsTasksWithoutFlag
);

const AddDistributedScoringTask = computationalTasksFlagWrapper(
  lazy(() => import("../../pages/AddDistributedScoringTask"))
);
const AddFeaturizeTask = computationalTasksFlagWrapper(
  lazy(() => import("../../pages/AddFeaturizeTask"))
);
const AddModelSelectionTask = computationalTasksFlagWrapper(
  lazy(() => import("../../pages/AddModelSelectionTask"))
);
const AddDescriptorsTask = computationalTasksFlagWrapper(
  lazy(() => import("../../pages/AddDescriptorsTask"))
);
const GenericTemplates = computationalTasksFlagWrapper(
  lazy(() => import("../../pages/GenericTemplates"))
);
const AddGenericTemplate = computationalTasksFlagWrapper(
  lazy(() => import("../../pages/AddGenericTemplate"))
);
const EditGenericTemplate = computationalTasksFlagWrapper(
  lazy(() => import("../../pages/EditGenericTemplate"))
);

const Workspace = workspaceFlagWrapper(
  lazy(() => import("../../pages/Workspace/Workspace"))
);

class App extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.routerRef = React.createRef();
  }

  redirectHomeIfLoggedIn() {
    return this.props.authState === "signedIn" ? (
      <Navigate to={this.context.routes.home.path} />
    ) : null;
  }

  isUserSignedIn() {
    return this.props.authState === "signedIn";
  }

  redirectIfNotLoggedIn(Component) {
    return this.isUserSignedIn() ? (
      <Component />
    ) : (
      <Navigate exact to={this.context.routes.root.path} />
    );
  }

  setupAPIHeaders() {
    Axios.defaults.headers.common["Content-Type"] = "application/json";
  }

  setupAuthenticationAndTokenRefresh() {
    Axios.interceptors.request.use(
      (config) => {
        // Token Refresh logic taken from https://github.com/aws-amplify/amplify-js/issues/446#issuecomment-389384338
        return Auth.currentSession()
          .then((session) => {
            if (_.includes(config.url, getAPIRoot())) {
              // User is logged in. Set auth header on all requests to our API
              config.headers[
                "Authorization"
              ] = `bearer ${session.idToken.jwtToken}`;
            }

            return Promise.resolve(config);
          })
          .catch(() => {
            if (_.includes(config.url, getAPIRoot())) {
              // Force logout for unauthenticated user trying to query our API.
              Auth.signOut();
              window.location.reload(true);
            } else {
              return Promise.resolve(config);
            }
          });
      },
      function (error) {
        if (ENV !== Environment.local) {
          Sentry.captureException(error);
        }
        return Promise.reject(error);
      }
    );

    Axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (ENV !== Environment.local) {
          Sentry.captureException(error);
        }

        if (!error || !error.response) {
          return Promise.reject(error);
        }

        const { status, config } = error.response;
        const isForbiddenMyselfCall =
          status === StatusCodes.FORBIDDEN && /api\/v1\/me/.test(config.url);
        const isUnauthorized = _.includes([StatusCodes.UNAUTHORIZED], status);

        if (
          (isForbiddenMyselfCall || isUnauthorized) &&
          _.includes(config.url, getAPIRoot())
        ) {
          // Force logout for unauthenticated and forbidden users trying to query our API.
          Auth.signOut();
          window.location.reload(true);
        }

        return Promise.reject(error);
      }
    );
  }

  componentDidMount() {
    this.setupAPIHeaders();
    this.setupAuthenticationAndTokenRefresh();

    setupGoogleAnalytics(ReactGA, () => this.sendGAEventOnHistoryChange());
    /**
     * SEND FIRST GA EVENT
     * For quick access to GA custom dimensions
     * https://analytics.google.com/analytics/web/#/a159953935w224665183p212981913/admin/custom-dimensions/
     */
    ReactGA.set(
      getGAMetaData(
        this.props.user,
        this.props.selectedOrganization,
        "APPLICATION_PRELOAD"
      )
    );
    ReactGA.pageview(window.location.pathname);
  }

  sendGAEventOnHistoryChange() {
    this.routerRef.current?.navigator?.listen((location, action) => {
      ReactGA.set(
        getGAMetaData(this.props.user, this.props.selectedOrganization, action)
      );
      ReactGA.pageview(location.pathname);
    });
  }

  render() {
    return (
      <Provider store={Store}>
        <Router ref={this.routerRef}>
          <AppLayout
            rootPath={this.context.routes.root.path}
            homePath={this.context.routes.homev2.path}
            authState={this.props.authState}
          >
            <Suspense fallback={<AppLoad appName={APP_NAME} pageLoad={true} />}>
              <Routes>
                <Route
                  exact
                  path={this.context.routes.root.path}
                  element={this.redirectHomeIfLoggedIn()}
                />
                <Route
                  exact
                  path={this.context.routes.home.path}
                  element={this.redirectIfNotLoggedIn(HomeV2)}
                />
                <Route
                  exact
                  path={this.context.routes.homev2.path}
                  element={this.redirectIfNotLoggedIn(HomeV2)}
                />
                <Route
                  exact
                  path={this.context.routes.drive.path}
                  element={this.redirectIfNotLoggedIn(Drive)}
                />
                <Route
                  exact
                  path={this.context.routes.driveTrash.path}
                  element={this.redirectIfNotLoggedIn(DriveTrash)}
                />
                <Route
                  exact
                  path={this.context.routes.shareDriveFile.path}
                  element={this.redirectIfNotLoggedIn(ShareDriveFile)}
                />
                <Route
                  exact
                  path={this.context.routes.profileSettings.path}
                  element={this.redirectIfNotLoggedIn(ProfileSettings)}
                />
                <Route
                  exact
                  path={this.context.routes.addProject.path}
                  element={this.redirectIfNotLoggedIn(AddProject)}
                />
                <Route
                  exact
                  path={this.context.routes.strategy.path}
                  element={this.redirectIfNotLoggedIn(StrategyReview)}
                />
                <Route
                  exact
                  path={this.context.routes.strategySummary.path}
                  element={this.redirectIfNotLoggedIn(() => (
                    <StrategyReview reviewSummaryMode={true} />
                  ))}
                />
                <Route
                  exact
                  path={this.context.routes.members.path}
                  element={this.redirectIfNotLoggedIn(Members)}
                />
                <Route
                  exact
                  path={this.context.routes.addMember.path}
                  element={this.redirectIfNotLoggedIn(AddMember)}
                />
                <Route
                  exact
                  path={this.context.routes.editMember.path}
                  element={this.redirectIfNotLoggedIn(EditMember)}
                />
                <Route
                  exact
                  path={this.context.routes.groups.path}
                  element={this.redirectIfNotLoggedIn(Groups)}
                />
                <Route
                  exact
                  path={this.context.routes.addGroup.path}
                  element={this.redirectIfNotLoggedIn(AddGroup)}
                />
                <Route
                  exact
                  path={this.context.routes.editGroup.path}
                  element={this.redirectIfNotLoggedIn(EditGroup)}
                />
                <Route
                  exact
                  path={this.context.routes.organizationSettings.path}
                  element={this.redirectIfNotLoggedIn(OrganizationSettings)}
                />
                <Route
                  exact
                  path={this.context.routes.moleculeEditorHelp.path}
                  element={this.redirectIfNotLoggedIn(MoleculeEditorHelp)}
                />
                <Route
                  exact
                  path={this.context.routes.organizationMolecules.path}
                  element={this.redirectIfNotLoggedIn(OrganizationMolecules)}
                />
                <Route
                  exact
                  path={this.context.routes.projects.path}
                  element={this.redirectIfNotLoggedIn(Projects)}
                />
                <Route
                  exact
                  path={this.context.routes.viewProject.path}
                  element={this.redirectIfNotLoggedIn(ViewProject)}
                />
                <Route
                  exact
                  path={this.context.routes.strategies.path}
                  element={this.redirectIfNotLoggedIn(Strategies)}
                />
                <Route
                  exact
                  path={this.context.routes.viewStrategy.path}
                  element={this.redirectIfNotLoggedIn(ViewStrategy)}
                />
                <Route
                  exact
                  path={this.context.routes.addStrategy.path}
                  element={this.redirectIfNotLoggedIn(AddStrategy)}
                />
                <Route
                  exact
                  path={this.context.routes.chemicalFilters.path}
                  element={this.redirectIfNotLoggedIn(ChemicalFilters)}
                />
                <Route
                  exact
                  path={this.context.routes.addChemicalFilter.path}
                  element={this.redirectIfNotLoggedIn(AddChemicalFilter)}
                />
                <Route
                  exact
                  path={this.context.routes.editChemicalFilter.path}
                  element={this.redirectIfNotLoggedIn(EditChemicalFilter)}
                />
                <Route
                  exact
                  path={this.context.routes.allTasks.path}
                  element={this.redirectIfNotLoggedIn(AllTasks)}
                />
                <Route
                  exact
                  path={this.context.routes.scoring.path}
                  element={this.redirectIfNotLoggedIn(ScoringTasks)}
                />
                <Route
                  exact
                  path={this.context.routes.distributedScoring.path}
                  element={this.redirectIfNotLoggedIn(DistributedScoringTasks)}
                />
                <Route
                  exact
                  path={this.context.routes.addDistributedScoringTask.path}
                  element={this.redirectIfNotLoggedIn(
                    AddDistributedScoringTask
                  )}
                />
                <Route
                  exact
                  path={this.context.routes.featurize.path}
                  element={this.redirectIfNotLoggedIn(FeaturizeTasks)}
                />
                <Route
                  exact
                  path={this.context.routes.addFeaturizeTask.path}
                  element={this.redirectIfNotLoggedIn(AddFeaturizeTask)}
                />
                <Route
                  exact
                  path={this.context.routes.modelSelection.path}
                  element={this.redirectIfNotLoggedIn(ModelSelectionTasks)}
                />
                <Route
                  exact
                  path={this.context.routes.addModelSelectionTask.path}
                  element={this.redirectIfNotLoggedIn(AddModelSelectionTask)}
                />
                <Route
                  exact
                  path={this.context.routes.descriptors.path}
                  element={this.redirectIfNotLoggedIn(DescriptorsTasks)}
                />
                <Route
                  exact
                  path={this.context.routes.addDescriptorsTask.path}
                  element={this.redirectIfNotLoggedIn(AddDescriptorsTask)}
                />
                <Route
                  exact
                  path={this.context.routes.molecularEditor.path}
                  element={this.redirectIfNotLoggedIn(MolecularEditor)}
                />
                <Route
                  exact
                  path={this.context.routes.genericTemplates.path}
                  element={this.redirectIfNotLoggedIn(GenericTemplates)}
                />
                <Route
                  exact
                  path={this.context.routes.addGenericTemplate.path}
                  element={this.redirectIfNotLoggedIn(AddGenericTemplate)}
                />
                <Route
                  exact
                  path={this.context.routes.editGenericTemplate.path}
                  element={this.redirectIfNotLoggedIn(EditGenericTemplate)}
                />
                <Route
                  exact
                  path={this.context.routes.workspaceView.path}
                  element={this.redirectIfNotLoggedIn(Workspace)}
                />
              </Routes>
            </Suspense>
          </AppLayout>
        </Router>
      </Provider>
    );
  }
}

export default App;
