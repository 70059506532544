import { PlusOutlined } from "@ant-design/icons";
import LocalesKeys from "@valencediscovery/kernel.locales";
import {
  DescriptorsTasksTable,
  PageHeader,
  withAppState,
  withDocumentTitle,
  withPermissionRestriction,
  withMainWrapperAndSideNavigation
} from "@valencediscovery/kernel.react";
import {
  PermissionActions,
  PermissionEntities
} from "@valencediscovery/kernel.store";
import { retrieveIntlMessage } from "@valencediscovery/kernel.utils";
import { Button, Col, Row } from "antd";
import { compose } from "lodash/fp";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { titleCase } from "title-case";
import MLSideNavigation from "../apps/kernel/components/MLSideNavigation/MLSideNavigation";
import { APP_NAME } from "../config";
import { AppContext } from "../context";

class DescriptorsTasks extends React.Component {
  static contextType = AppContext;
  static displayName = "DescriptorsTasks";

  render() {
    return (
      <>
        <PageHeader
          title={
            <FormattedMessage
              id={LocalesKeys.descriptorsTaskHistoryTitle}
              children={([title]) => titleCase(title)}
            />
          }
          description={
            <FormattedMessage
              id={LocalesKeys.descriptorsTaskHistoryDescription}
            />
          }
          actions={
            <Link to={this.context.routes.addDescriptorsTask.path}>
              <Button type="primary" icon={<PlusOutlined />}>
                <span>
                  <FormattedMessage id={LocalesKeys.addDescriptorsTask} />
                </span>
              </Button>
            </Link>
          }
          breadcrumbs={[
            {
              title: this.props.intl.formatMessage({ id: LocalesKeys.home }),
              path: this.context.routes.home.path
            },
            {
              title: this.props.intl.formatMessage({
                id: LocalesKeys.computationalTasks
              })
            },
            {
              title: titleCase(
                this.props.intl.formatMessage({
                  id: LocalesKeys.descriptorsTaskHistoryTitle
                })
              )
            }
          ]}
        />
        <Row>
          <Col span={24}>
            <DescriptorsTasksTable />
          </Col>
        </Row>
      </>
    );
  }
}

export default compose(
  (component) => injectIntl(component, { forwardRef: true }),
  withAppState,
  withDocumentTitle({
    name: retrieveIntlMessage(LocalesKeys.descriptorsTaskHistoryTitle).format(),
    prefix: APP_NAME
  }),
  withMainWrapperAndSideNavigation({ SideNavigation: MLSideNavigation }),
  withPermissionRestriction({
    permissions: [[PermissionActions.view, PermissionEntities.descriptorstask]]
  })
)(DescriptorsTasks);
